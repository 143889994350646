@use "../../../../master.scss";

.edit-company-modal {
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 600px) {
    margin: 10px 0;
  }
  * {
    font-family: "Poppins";
  }
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-body {
    position: relative;
    padding: 30px 30px 42px 30px;
    background-color: #fbfbfb;

    .modal-body {
      .title {
        margin-bottom: 34px;
        h3 {
          font-weight: 600;
          font-size: 34px;
          line-height: 38px;
          margin: 0;
          color: #1c2b48;
          font-family: seventyTwoSemiBold;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 31px;
        top: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .button-parent {
        button {
          span {
            font-size: 18px;
          }
        }
      }
    }

    .modal-footer {
      margin-top: 25px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end !important;

      //   .primary-btn {
      //     background: #6f7ced !important;
      //     &:hover {
      //       background-color: master.$primary-hover-color !important;
      //       color: #fff;
      //     }
      //     &:disabled {
      //       background-color: master.$primary-disabled-color !important;
      //       cursor: auto;
      //     }
      //   }

      //   button {
      //     border-radius: 12px;
      //     padding: 12px;
      //     background: #f06b6b;
      //     font-weight: 500;
      //     font-size: 16px;
      //     min-width: 170px;
      //     color: #fff;

      //     &:hover {
      //       background: #d14e4e;
      //     }

      //     &:disabled {
      //       background-color: rgba(240, 107, 107, 0.6) !important;
      //     }
      //   }
    }
  }
}
