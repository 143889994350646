@use "../../../master.scss";

.ant-input-affix-wrapper {
  height: 56px;
  border-radius: 12px;
  // border: none;
  .ant-input-prefix {
    margin-right: 10px;
  }
  input {
    font-size: 15px;

    &::placeholder {
      color: #96a0ad;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-input-affix-wrapper {
    font-size: 13px;
    height: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  button.ant-btn {
    font-size: 14px;
    height: 40px;
  }
}
