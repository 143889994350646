.buttons-container {
  display: flex;
  background: rgba(0, 112, 242, 0.2);
  border-radius: 12px;
  overflow: hidden;

  div {
    width: 160px;
    height: 45px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }

  .active-btn {
    background: rgba(0, 112, 242, 1);

    p,
    h6 {
      color: #ffffff !important;
    }
  }

  p {
    font-family: "DM Sans";
    margin: 0;
    color: #021349;
    font-size: 16px;
    font-weight: 500;
  }

  h6 {
    font-family: "Lato";
    margin: 0;
    color: #1c2b48;
  }
}
