.public-footer {
  .copyright-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px 0;
    border-top: 1px solid rgba(36, 61, 76, 0.2);
    margin-top: 88px;
  }
  .seperator {
    height: 2px;
    width: 35px;
    background-color: #0070f2;
    opacity: 0.8;
    border: 7px;
  }
  .links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 20px;
    .link-item {
      font-family: seventyTwoLight;
      font-size: 16px;
      line-height: 26px;
      color: #465e7d;
    }
  }
  .contacts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  @media (min-width: 767px) {
    gap: 40px;

  }
    margin-top: 15px;
    .contact-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 27px;
    }
  }
  .social-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    margin-top: 12px;
    .social-item {
      cursor: pointer;
    }
  }
}
