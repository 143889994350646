@use "../../master.scss";

.buy-sub-modal {
	border-radius: 20px;
	overflow: hidden;
	background: rgba(251, 251, 251, 1);
	* {
		font-family: "Poppins";
	}
	.ant-modal-content {
		padding: 0;
	}
	.ant-modal-body {
		position: relative;
		padding: 21px 29px;

		.modal-body {
			.title {
				margin-bottom: 20px;
				h3 {
					font-weight: 600;
					font-size: 24px;
					line-height: 22px;
					margin: 0;
					color: #1c2b48;
				}

				p {
					margin: 0;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					color: #1c2b48;
					opacity: 0.5;
				}
			}

			.close-btn {
				position: absolute;
				right: 29px;
				top: 21px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
			.payment-form {
				.package-details {
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(0, 112, 242, 0.1);
					border: 1px solid #0070f2;
					border-radius: 12px;
					padding: 30px 0;
					margin-bottom: 22px;
				}
			}

			.card-element-parent {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: 56px;
				width: 100%;
				padding: 10px;
				border-radius: 12px;
				// margin-bottom: 20px;
				font-family: seventyTwoRegular;
				border: 1px solid rgba(133, 140, 148, 0.1);
				div {
					width: 100%;
					.card-element {
						width: 100%;
					}
				}
			}
		}

		.modal-footer {
			margin-top: 25px;
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: flex-end !important;

			//   .primary-btn {
			//     background: #6f7ced !important;
			//     &:hover {
			//       background-color: master.$primary-hover-color !important;
			//       color: #fff;
			//     }
			//     &:disabled {
			//       background-color: master.$primary-disabled-color !important;
			//       cursor: auto;
			//     }
			//   }

			//   button {
			//     border-radius: 12px;
			//     padding: 12px;
			//     background: #f06b6b;
			//     font-weight: 500;
			//     font-size: 16px;
			//     min-width: 170px;
			//     color: #fff;

			//     &:hover {
			//       background: #d14e4e;
			//     }

			//     &:disabled {
			//       background-color: rgba(240, 107, 107, 0.6) !important;
			//     }
			//   }
		}
	}
}
