@use "../../../../master.scss";

.pricingSectionWrapper {
  padding-bottom: 120px;


  .pricingHeading {
      
      font-size: 28px !important;
    
    @media (min-width: 767px) {
      
        font-size: 55px !important;
      
    }
  }


  .button-parent {
    button {
      max-width: 100%;
      span {
        font-family: seventyTwoSemiBold;
      }
    }
  }
  .planMainWrapper {
    margin-top: 30px;

    .planWrapper {
      background-color: #fff;
      // background-color: rgba(246, 250, 253, 1);
      padding: 50px 40px;
      border-radius: 24px;
      &.popular {
        background-color: #021349;
        position: relative;
        .price {
          color: #fff;
          span {
            color: #d9dbe9;
          }
        }
        .priceItemWrap {
          svg {
            path:first-child {
              fill: #fff;
            }
            path:last-child {
              stroke: rgba(74, 58, 255, 1);
            }
          }
        }
      }
      .priceItemWrap {
        svg {
          width: 26px;
          height: 26px;
          min-width: 26px;
          min-height: 26px;
        }
      }
      .popular-plan-tag {
        position: absolute;
        top: 32px;
        right: 31px;
        padding: 12px 24px;
        background: rgba(255, 255, 255, 0.2);
        color: #fff;
        font-family: seventyTwoLight;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .priceItemWrap {
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 16px;
      }

      .price {
        font-size: 54px;
        line-height: 66px;
        color: rgba(3, 25, 70, 1);
        font-family: "seventyTwoBold";

        span {
          font-size: 20px;
          color: rgba(70, 94, 125, 1);
          font-family: "seventyTwoSemiBold";
        }
      }
    }
  }

  .desc {
    width: 60%;
    color: rgba(70, 94, 125, 1);
    margin: auto !important;
    margin-top: 12px !important;
  }
}
