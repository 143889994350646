.open-bids-listing-wrapper {
  .bids-section {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-top: 25px;

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;
      flex-wrap: wrap;
      gap: 10px;

      .table-filters-wrapper {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
      }
      @media only screen and (max-width: 1228px) {
        .search-box {
          width: 100%;
        }
      }
    }
  } 
  
  .filter-wrapper{
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 20px;
  }
  .formClassName{
    margin-bottom: 0px !important;
  }
  .selectSize{
    min-height: 43px !important;
    height: 43px !important;
    .ant-select-selector{
      min-height: 43px !important;
    }
  }
}
