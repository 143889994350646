@use "../../../../master.scss";

.heroSectionWrapper {
  padding: 120px 0 40px;
  background: linear-gradient(
    to right,
    #f6fafd 51.5%,
    #eff5ff 48.5%
  ); // Linear gradient with 50% width
  .qualityHead{
    line-height: normal !important;
    @media (min-width: 767px) {
      font-size: 55px;
      line-height: 68.99px;
    }
  }
  @media (max-width: 991px) {
    background: linear-gradient(
      to bottom,
      #f6fafd 51.5%,
      #eff5ff 48.5%
    ); // Linear gradient with 50% width
  }
  .heroSection {
    display: flex !important;
    margin-bottom: 10px;

    .heroCol1 {
      margin-top: 30px;

      .buttonWrap {
        margin-top: 20px;

        button {
          width: auto;
          min-width: 186px;
        }
      }
    }

    .heroCol2 img {
      width: 100%;
    }
  }

  .slick-dots {
    position: absolute;
    right: 0px;
    width: unset;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    li {
      padding: 0 !important;
      width: 12px !important;
      height: 12px !important;
      background: rgba(3, 25, 70, 0.5) !important;
      border: none !important;
      font-size: 0;
      border-radius: 100px;
      margin: 0 !important;
      transition: all 0.2s ease-in-out;

      button {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;

        &::before {
          display: none !important;
        }
      }
    }

    li.slick-active {
      background-color: rgba(0, 112, 242, 1) !important;
      height: 31.39px !important;
      transition: all 0.2s ease-in-out;
    }
  }

  .imgWrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px 40px;
    margin-top: 15px;
  }
}
