.custom-uploader {
  button {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .ant-upload {
    height: 166px;
    background: rgba(0, 112, 242, 0.1);
    border-color: rgba(0, 112, 242, 1);
    border-radius: 12px;
    .ant-upload-drag-icon {
      margin-bottom: 12px !important;
    }
  }
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 17px 23px;
  background: rgba(0, 112, 242, 0.1);
  border-radius: 12px;
  .item-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    
  }
  .c-p {
    height: 21px;
    width: 21px;
  }
}
