@use "../../../master.scss";

.common-text-area {
  // background: master.$field-background;
  background: #fff;
  height: 44px;
  border-radius: 12px;
  //   border: none;
  font-size: 16px !important;
  height: 170px;
  padding: 18px 22px;
  color: rgba(0, 0, 0, 0.88) !important;
  font-family: seventyTwoRegular !important;
  &::placeholder {
    color: #96a0ad;
    font-size: 16px;
    opacity: 1;
  }
}
.ant-form-item-control-input-content {
  & ::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .common-text-area {
    height: 39px;
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-text-area {
    font-size: 14px;
    height: 44px;
  }
}

@media only screen and (min-width: 992px) {
  .common-text-area {
    font-size: 15px;
    height: 49px;
  }
}
