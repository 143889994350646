@use "../../../../master.scss";

.bid-download {
  border: 1px solid rgb(master.$theme-color, 0.6);
  background-color: rgb(master.$theme-color, 0.2);
  border-radius: 12px;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.ongoing-option-wrapper {
  .buttonWrapper {
    display: flex !important;
    flex-wrap: wrap;

    .edit-icon {
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      background-color: rgb(master.$theme-color, 0.3);
      border-radius: 12px;
    }
    @media only screen and (max-width: 854px) {
      .button-parent {
        min-width: 100%;
        margin-top: 6px;
        button {
          min-width: 100%;
          span {
            white-space: wrap;
          }
        }
      }
      .edit-icon {
        margin-top: 6px;
      }
    }
  }
  .questions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
    .question-item {
      padding: 20px;
      border-radius: 12px 12px 12px 0;
      background: #fff;
      border: 1px solid rgba(217, 217, 217, 0.3);
      cursor: pointer;
      &.selected {
        border-color: #0070f2;
      }
    }
    .ask-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // position: absolute;
      // bottom: 0;
      // width: 100%;

      button {
        span {
          font-family: "seventyTwoBold";
          font-size: 16px;
        }
      }
    }
  }
}
