.image-uploader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 141px;
  min-width: 141px;
  max-width: 141px;
  max-height: 141px;
  overflow: hidden;
  border-radius: 50%;
  background: rgba(0, 112, 242, 0.1);
  border: 1px solid #0070f2;
  margin-bottom: 20px;
  input {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  img {
    // position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    min-height: 141px;
    min-width: 141px;
    max-width: 141px;
    max-height: 141px;
  }
}

.upload-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .image-uploader {
    height: 106px;
    width: 106px;
    min-height: 106px;
    min-width: 106px;
    margin: 0 !important;
    svg {
      height: 50px;
      width: 50px;
    }
  }
  .upload-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    color: #0070f2;
    border: 1px solid #0070f2;
    border-radius: 12px;
    font-size: 14px;
    font-family: seventyTwoSemiBold;
    line-height: 16px;
    cursor: pointer;
  }
  input {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  img {
    // position: absolute;
    height: 106px;
    width: 106px;
    border-radius: 50%;
    object-fit: cover;
  }
}
