.group-member-options {
  p {
    text-align: left;
  }
}

.diabled-menu { 
  cursor: not-allowed !important;
  circle {
    fill: grey;
  }
}