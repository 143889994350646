@use "../../../master.scss";

.ant-table-wrapper {
  .ant-table {
    border: 1px solid master.$border-color;
    .ant-table-thead > tr > th {
      background: #fff;
      border-color: #1c2b4833;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      tr > td {
        border-color: master.$border-color;
        padding: 20px;
      }
      tr:hover > td {
        background: none;
      }
    }
  }
  .ant-table-column-sorters {
    justify-content: flex-start;
    gap: 5px;
    .ant-table-column-title {
      flex: unset;
    }
  }
}
