@use "../../../master.scss";

.ant-select-multiple {
  .ant-select-selector {
    min-height: 56px !important;
  }
  .ant-select-selection-item-remove{
    padding-right: 5px !important;
  }

  .ant-select-selection-placeholder {
    padding-left: 10px;
  }

  .ant-select-selection-search {
    margin-inline-start: 0;
  }
}

.ant-select {
  min-height: 56px !important;
  border-radius: 12px;

  .ant-select-selector {
    // border: none !important;
    border-radius: 12px;
    padding: 4px 20px !important;
    .ant-select-selection-item {
      padding-inline-end: 0;
    }

    .ant-select-selection-placeholder {
      color: #96a0ad !important;
      font-size: 15px !important;
      font-weight: 400;
    }
  }

  .ant-select-arrow {
    color: black;
  }
}

.Complete {
  .ant-select-selector {
    color: master.$theme-color;
  }
  .ant-select-arrow {
    color: master.$theme-color;
  }
}
