.profile-wrapper {
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  min-height: calc(100vh - 150px);
  .profile-fileds-wrapper {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7ecf1;
  }
  .change-pass-option {
    border: 1px solid #0070f2;
    background: rgba(0, 112, 242, 0.1);
    width: 100%;
    border-radius: 12px;
    padding: 20px;
  }
}
