.sellerbid-parent {
    .list-parent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sellerbid-card {
            display: flex;
            justify-content: space-between;
            border: 1px solid #e7ecf1;
            border-radius: 12px;
            padding: 15px 10px;
            gap: 20px;
            flex-wrap: wrap;
        }
    }

    .cancel-reason {
        margin-top: 20px;
    }
}
