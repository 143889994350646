.bid-oppurtunity-parent {
  padding-bottom: 50px;
  .headingWordBreak{
    font-size: 28px;

    @media (min-width: 767px) {
      font-size: 55px;
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 32px;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
  .left-side {
    padding-right: 32px;
    @media (max-width: 991px) {
      padding-right: 0;
    }
  }
}
