.auth-box {
  margin-top: 30px;
  background-color: #f6fafd;
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  overflow-y: auto;
  button {
    span {
      font-family: seventyTwoSemiBold;
    }
  }
  .ant-btn-primary:disabled,
  .ant-btn-primary.ant-btn-disabled {
    opacity: 0.5 !important;
    color: #fff !important;
  }
  .custom-otp-input input {
    min-width: 30px;
    min-height: 60px;
    width: 100% !important;
    border: 1px solid rgba(28, 43, 72, 0.1) !important;
    background: #fff;
    border-radius: 12px;
    outline: none;
    height: 100% !important;
  }

  .custom-otp-input input:focus {
    border: 1px solid #0070f2 !important;
  }
  .otpCodeInput {
    width: 82px;
    height: 60px;
    border-radius: 12px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: "none";
    }
  }
  .auth-image-wrapper {
    width: 50%;
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 75px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .login-container {
    width: 50%;
    padding: 75px;
    display: flex;
    align-items: flex-end;
    height: max-content;
    .wrong-acc-text {
      color: #031946;
      font-size: 16px;
      margin-bottom: 30px;
      a {
        font-weight: 600;
        text-decoration: underline;
        color: #031946;
      }
    }
    .login-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      .login-form-wrapper {
        // width: 50%;
        height: 100%;
        // padding: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .resend-timer {
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          width: 100%;
          color: #031946;
        }
        .login-form {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;

          .forgot-pass-link {
            margin-bottom: 24px;
            font-size: 16px;
          }
          div {
            width: 100%;
          }
          .signup-option-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 18px;
            margin-bottom: 20px;

            .signup-option {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              padding: 19px 0;
              border: 1px solid #bdc4cd;
              color: #bdc4cd;
              border-radius: 12px;
              font-size: 16px;
              cursor: pointer;
              &.active {
                color: #fff;
                background: #031946;
              }
            }
            @media only screen and (max-width: 600px) {
              flex-direction: column;
              justify-content: center;
              .signup-option {
                width: 100%;
              }
            }
          }
        }
      }
      .signup-prompt {
        color: #465e7d;
        font-size: 16px;
        font-weight: 400;
        margin-top: 75px;
        a {
          color: #0070f2;
          font-weight: 500;
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .auth-image-wrapper {
      display: none;
    }
    .login-container {
      width: 100%;
      padding: 75px 40px;
      .signup-prompt {
        text-align: center;
      }
    }
  }
}
