.login-messages {
  padding: 20px 20px 40px 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  //   backdrop-filter: blur(15px);
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  border-radius: 14px;
  //   position: relative;
  .card-background {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(
      10px
    ); /* Apply the blur effect only to the background */
    border-radius: 14px;
  }
  .ant-carousel {
    .slick-dots-bottom {
      bottom: 0;
      left: 0;
      width: max-content;
    }
    .slick-dots {
      li {
        button {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.5);
          &:focus {
            opacity: 0.5 !important;
          }
          &:hover {
            opacity: 0.5 !important;
          }
        }
      }
      li.slick-active {
        width: 43px;
        button {
          height: 13px;
          width: 100%;
          border-radius: 10px;
          background: #0070f2;
          opacity: 1 !important;
        }
      }
    }
  }
}
