.subscription-container {
  // border-radius: 12px;
  min-height: calc(100vh - 150px);
  .active-subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    padding: 36px 30px;
    background: #fff;
    border-radius: 12px;
    margin-top: 50px;
    margin-bottom: 40px;
    .button-parent {
      max-width: 100%;
      button {
        max-width: 100%;
        border-radius: 16px;
        span {
          font-size: 16px;
          white-space: wrap;
        }
      }
    }
  }
  .add-card-option {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 112, 242, 0.1);
    color: #0070f2;
    border: 1px dashed #0070f2;
    font-family: seventyTwoSemiBold;
    font-size: 24px;
    padding: 33px 0;
    width: 100%;
    border-radius: 12px;
    margin-top: 20px;
    cursor: pointer;
  }
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26.5px 30px;
    background: #fff;
    border-radius: 12px;
    .card-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }
    @media (max-width: 600px) {
      align-items: flex-start;
      .card-info {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}

.info-card {
  padding: 36px 30px;
  background: #fff;
  border-radius: 12px;
  display: flex;
}
