@use "../../../master.scss";

h1.ant-typography {
    font-weight: 600;
    // font-size: 32px;
    // line-height: 48px;
    font-size: 31px;
    line-height: 40.56px;

    color: master.$text-color;
}

h3.ant-typography {
    font-size: 24px;
    line-height: 28.8px;
    // color: #ffffff;
}

h2.ant-typography {
    font-weight: 600;
    font-size: 18.72px;
    line-height: 28.8px;
    // color: #ffffff;
}

@media only screen and (max-width: 767px) {
    h1.ant-typography {
        font-size: 28px;
        line-height: 37.56px;
    }

    h2.ant-typography {
        font-size: 15.72;
        line-height: 25.8px;
        // color: #ffffff;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    h1.ant-typography {
        font-size: 31px;
        line-height: 40.56px;
    }
    h2.ant-typography {
        font-size: 18.72px;
        line-height: 28.8px;
    }
}

@media only screen and (min-width: 992px) {
    h1.ant-typography {
        font-size: 34px;
        line-height: 43.56px;
    }
    h2.ant-typography {
        font-size: 21.72px;
        line-height: 31.8px;
    }
}
