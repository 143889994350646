@use "../../../master.scss";

.common-modal {
  min-width: 280px !important;
  max-width: 1000px !important;
  width: 70% !important;
  padding: 20px 0 20px 0;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 20px;
    // background: master.$grey-color;
    // border: 15px solid master.$light-grey-color;
    // background-color: master.$dark-color;

    .ant-modal-close {
      color: black;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      .ant-modal-close-x {
        // color: master.$grey-color;
        // font-size: 10px;
      }
    }
    .ant-modal-body {
      padding: 40px 20px 20px 20px;
    }
  }

  @include master.sm {
    width: 100% !important;
    padding: 20px 20px 0 20px;
  }
}

.confirmation-modal {
  width: 40% !important;
  min-width: 280px !important;
  .ant-modal-body {
    .confirm-content {
      width: 100%;
      margin-top: 20px;
      .button-section {
        display: flex;
        gap: 10px;
        .button-parent {
          width: 100%;
        }
        @include master.sm {
          flex-direction: column;
        }
      }
    }
  }
}

.large-modal {
  width: 70% !important;
  max-width: 800px !important;
}
