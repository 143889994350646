.subscription-wrapper {
  padding: 80px 0;
  min-height: calc(100vh - 89px);
  height: max-content;
  background-color: #f6fafd;
  .subscriptions-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    gap: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 60px 10px 60px;

    @media only screen and (max-width: 1427px) {
      justify-content: flex-start;
    }

    .subscription-item {
      height: 100%;
      border-radius: 24px;
      background: #fff;
      min-width: 310px;
      padding: 30px;
      //   box-shadow: 0px 55px 99.69999694824219px 0px #a7b5be;
      box-shadow: 0 0px 3px 0 rgba(36, 61, 76, 0.1); /* Add a subtle box shadow */
      transition: all 0.3s ease-out;
      .sub-item-heading {
        border-bottom: 1px solid rgba(36, 61, 76, 0.3);
        padding-bottom: 15px;
      }
      .sub-description {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
        span {
          svg {
            margin-right: 10px;
          }
        }
      }
      &:hover {
        background: #031946;
        cursor: pointer;
        padding: 45px 30px;
        // min-height: calc(100% + 50px);
        .sub-item-heading {
          border-color: #fff;
        }
        * {
          color: #fff !important;
        }
      }
    }
  }
}
