.about-wrapper {
    @media (min-width: 600px) {
      .ant-typography {
        font-size: 45px !important;
        line-height: 55px !important;
      }
    }
  }

  

  .about-wrapper {
    padding: 120px 0 40px;
    min-height: 100vh;
  }