.manage-sub-container {
  // border-radius: 12px;
  min-height: calc(100vh - 150px);
  .package-card-wrapper {
    padding: 20px 25px;
    background: #fff;
    border-radius: 24px;
    &.active {
      background: #031946;
      * {
        color: #fff !important;
      }
      .package-info {
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
    .package-info {
      border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
      padding-bottom: 15px;
    }
    .package-benefits {
      padding-top: 15px;
    }
    button {
      span {
        font-size: 16px;
        font-family: seventyTwoBold;
      }
    }
  }
}
