.dashboard {
  .ongoing-bid {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    height: 100px;
    border-radius: 20px;

    .icon-wrapper {
      background-color: #f4f7fe;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
  }

  .Completed {
    background: linear-gradient(135deg, #1883ff 0%, #0067df 100%);
  }

  .bids-section {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-top: 25px;
  }
  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    flex-wrap: wrap;
    gap: 10px;

    .table-filters-wrapper {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;
    }
    @media only screen and (max-width: 1228px) {
      .search-box {
        width: 100%;
      }
    }
  }
}
