@use "../../../master.scss";

.filter-option {
    // background-color: master.$theme-light-color;
    padding: 6px;
    border-radius: 10px;

    .option {
        cursor: pointer;
        padding: 13px 16px;
    }

    .active {
        background: master.$theme-color;
        border-radius: 10px;
        transition: background 0.3s;

        p {
            color: #ffffff;
        }
    }
}
