.industry-section-wrapper {
  padding: 65px 0;
  background: linear-gradient(to bottom, #021349 68%, #f6fafd 32%);
  border-radius: 56px 56px 0 0;
  .rightAidHeading{
    @media (min-width: 767px) {
      font-size: 55px;
    }
  }
  .industry-card {
    max-width: 525px;
    // min-width: 525px;
    // margin-right: 20px;
    // width: 525px;
    height: 421px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;

    box-shadow: 0px 0px 16px rgb(167 181 190 / 17%);
    img {
      width: 100%;
      height: 310px;
      object-fit: cover;
    }
    .industry-info {
      position: absolute;
      padding: 40px 28px;
      bottom: 0;
      left: 0;
      width: 100%;
      // max-width: 100%;
      background: #fff;
      border-radius: 15px 15px 0 0;
    }
  }
  .owl-prev {
    position: absolute;
    top: 0;
    right: 80px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .owl-next {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  // .owl-item {
  //   margin-right: 18px !important;
  // }
}
