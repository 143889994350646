.custom-switch {
  .ant-switch-handle {
    height: 13px;
    width: 13px;
    top: 4px;
    left: 6px;
  }
  background: #e0e4f0;
  &:hover {
    background: #e0e4f0 !important;
  }
  &.ant-switch-checked {
    background: #e0e4f0;
    .ant-switch-handle {
      right: 4px;
    }
    .ant-switch-handle::before {
      background-color: #219653;
    }
  }
}
