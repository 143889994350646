.auth-header {
  padding: 20px 75px;
  background: #021349;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  @media (max-width: 768px) {
    padding: 10px 35px;
  }
  * {
    font-family: "Poppins" !important;
  }
  .header-options {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: flex-end;
    transition: all 0.3s ease-out;

    .header-nav {
      color: #fff;
      font-size: 16px;
    }
    .header-nav-active {
      border-bottom: 2px solid #fff;
      padding-bottom: 2px;
    }
    .button-parent {
      width: 163px;
      button {
        padding: 11px 0;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: max-content;
      }
    }
    @media (max-width: 768px) {
      height: 0px;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      &.active {
        padding-top: 25px;
        height: 310px;
      }
    }
  }
  .toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100%;
    cursor: pointer;

    .togglebtn {
      border: none;
      background: transparent;
      display: none;
      height: 35px;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      span {
        background: #fff;
        width: 26px;
        height: 3px;
        transition: 0.4s all;
        display: block;
        border-radius: 5px;
        margin-bottom: 6px;
        position: relative;
        &:nth-child(2) {
          width: 24px;
        }
        &:last-child {
          width: 17px;
        }
      }
      &.active span {
        width: 30px;
        &:first-child {
          transform: rotate(45deg);
          top: 5px;
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
          bottom: 3.7px;
        }
        &:last-child {
          display: none !important;
        }
      }
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}
