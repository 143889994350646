@use "../../../../master.scss";

.why-Central-parent {
  padding: 20px 0 100px;
  @media (min-width: 767px) {
    padding: 147px 0 142px;

  }
  .whyHead{
    @media (min-width: 767px) {
      font-size: 55px;
    }
  }
  .left-side {
    padding-right: 80px;
  }
  .right-side {
    padding-left: 80px;
  }
  .bid-noti-col {
    padding-right: 40px;
  }
  .better-bid-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
  }
  @media (max-width: 991px) {
    .left-side {
      padding-right: 0;
      margin-bottom: 40px;
    }
    .right-side {
      padding-left: 0;
    }
    .bid-noti-col {
      padding-right: 0;
    }
    .better-bid-col {
      padding-left: 0;
    }
  }
  .icon {
    background-color: #021349;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    margin-bottom: 14px;
    @include master.flex-center;
  }
}
