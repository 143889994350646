@use "../../../master.scss";

.ant-picker {
  height: 56px;
  border-radius: 12px;
  width: 100%;
  // border: none;
  .ant-picker-input {
    input {
      font-size: 16px;
      font-family: "seventyTwoRegular";
      padding: 0 10px;

      &::placeholder {
        color: #96a0ad;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-picker {
    height: 46px;
    .ant-picker-input {
      input {
        font-size: 13px;
      }
    }
  }
}
