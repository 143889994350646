.stepDetailMainWrapper {
  position: relative;
  .backArrowWrapper {
    display: flex;
    align-items: center;
  }
  button {
    span {
      font-family: seventyTwoSemiBold;
    }
  }
  .stepDetailWrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-top: 30px;

    .stepsWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 30px;
      position: relative;

      .stepsItemWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 16%;
        z-index: 1;
        cursor: pointer;
        .stepCountWrap {
          background-color: rgba(0, 112, 242, 1);
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-bottom: 9px;
          transition: all 0.3s ease-in;

          .paragraph-parent p {
            color: #fff;
            font-size: 14px;
          }
          &.inactive {
            background-color: rgb(149, 189, 235);
          }
        }

        > .paragraph-parent {
          color: rgba(2, 19, 73, 1);
          font-size: 12px;
          text-align: center;
        }
      }

      .stepsUnactiveLine {
        background-color: rgba(2, 19, 73, 0.1);
        width: 85%;
        height: 1px;
        position: absolute;
        left: 7.5%;
        top: 15px;
      }
      .stepsActiveLine {
        background-color: rgba(0, 112, 242, 1);
        width: 17%;
        height: 1px;
        position: absolute;
        left: 7.5%;
        top: 15px;
        transition: all 0.3s ease-in;
      }
    }
  }

  .currentBitWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .tableWrapper {
    margin-top: 30px;

    thead th {
      background-color: rgba(229, 241, 254, 1) !important;
      border: none;
    }
    &.pricing-table-wrapper {
      *:not(.ant-input-affix-wrapper) {
        border-color: transparent !important;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .button {
      min-width: 200px;
    }

    .buttonWrapper {
      margin-top: 0;

    }

    
    @media (max-width: 450px) {
      flex-wrap: wrap;
      .button-parent {
        width: 100% !important;
        margin-top: 10px !important;

        .button {
          width: 100% !important;

          button {
            width: 100% !important
          }
        }
      }
    }

  }
}

.showInMob {
  display: none;
  @media (max-width: 600px) {
    display: block !important;
  }
}

.showInWeb {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
