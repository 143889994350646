.create-bid-wrapper {
  .create-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 11px;
    margin-top: 30px;
    .button-parent {
      width: 197px;
      height: 56px;
      button {
        width: 100%;
        height: 100%;
      }
      span {
        font-size: 18px;
        font-family: "seventyTwoBold";
      }
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;

      .button-parent {
        width: 100%;
      }
    }
  }
}
