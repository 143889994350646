@use "../../../master.scss";

.sidebar-parent {
  background-color: #fff;
  width: 254px;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .test {
    width: 30px;
    height: calc(100vh - 93px);
    position: fixed;
    background: #f6fafd;
    left: 230px;
    top: 91px;
    z-index: 99999;
  }

  .dot-parent {
    width: 50px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 76px;
    z-index: 9999999;

    .dot {
      width: 8px;
      height: 8px;
      background-color: master.$theme-color;
      border-radius: 3px;
    }
  }

  .main-logo {
    margin: 38px auto;
  }

  .create-bid {
    background-color: master.$theme-light-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 45px 20px 20px;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }

  .ant-divider {
    width: 75%;
    min-width: 75%;
    margin: 40px 45px 40px 20px;
  }

  .content-parent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .active {
      .content {
        p {
          color: master.$theme-color;
          font-weight: 600;
        }
        path {
          fill: master.$theme-color;
        }
        rect {
          stroke: master.$theme-color;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 30px;

      .content {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }

      .active-dot {
        width: 50px;
        height: 60px;
        background: #ffffff;
        position: absolute;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999999;
        right: 6px;

        .dot {
          width: 8px;
          height: 8px;
          background-color: master.$theme-color;
          border-radius: 50%;
          margin-left: 15px;
        }
      }
    }
  }

  .logout-but {
    margin: 0px 47px 0 25px;
    button {
      span {
        font-family: seventyTwoBold;
        font-size: 16px;
      }
    }
  }

  @include master.md {
    display: none;
  }
}

.Mobile-sidebar-parent {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  .main-logo {
    margin: 15px auto;
  }

  .create-bid {
    background-color: master.$theme-light-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 30px;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }

  .content-parent {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .active {
      .content {
        p {
          color: master.$theme-color;
          font-weight: 600;
        }

        path {
          fill: master.$theme-color;
        }
        rect {
          stroke: master.$theme-color;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
      .dot {
        width: 5px;
        height: 5px;
        background-color: master.$theme-color;
        border-radius: 50%;
      }
    }
  }
}
