.terms-wrapper {
  padding: 120px 0 40px;
  min-height: 100vh;
}
.terms-heading{
  line-height: normal;
  @media (min-width: 767px) {
    font-size: 36px;
    line-height: 68.99px;
  }
}