@use "../../master.scss";

.auth-wrapper {
  margin-top: 95px;
  height: calc(100vh - 95px);
  @media only screen and (max-width: 768px) {
    margin-top: 76px;
    height: calc(100vh - 76px);
  }
}
