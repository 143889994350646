@use "../../../../master.scss";

.bid-listing-table {
  .action-col-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .statusButton {
      padding: 3px 0;
      background-color: master.$theme-light-color;
      border-radius: 12px;
      text-align: center;
      width: 84px;
      &.open {
        background-color: master.$theme-light-color;
        p {
          color: master.$text-greenish-color;
        }
      }
      &.complete {
        background-color: rgba(0, 112, 242, 0.1);
        p {
          color: rgba(0, 112, 242, 1);
        }
      }
      &.cancelled {
        background-color: rgba(228, 4, 4, 0.1);
        p {
          color: rgba(228, 4, 4, 1);
        }
      }
      p {
        color: master.$text-greenish-color;
        font-size: 10px;
        font-family: "Lato";
        font-weight: 700;
      }
    }
    .view-detail {
      text-align: center;
      width: 104px;
      padding: 9px 0;
      background: #c5defb;
      border-radius: 12px;
      cursor: pointer;
    }
  }
}
