.home-table-wrapper {
  padding-bottom: 150px;
  .ant-table-wrapper .ant-table {
    background: transparent;
    border-color: transparent;
  }
  .ant-table-wrapper .ant-table .ant-table-tbody tr > td {
    border-color: #f1f3f7;
  }
  .ant-table-wrapper .ant-table .ant-table-thead > tr > th {
    background-color: #f1f3f7;
    border-color: transparent;
    border-radius: 6px !important;
    font-family: seventyTwoSemiBold;
    font-size: 16px;
    color: #2b3144;
  }
  .table-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: seventyTwoSemiBold;
    font-size: 14px;
  }
  .statusButton {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 8px 18px;
    border-radius: 25px;
  }
}
