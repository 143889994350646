@use "../../../../master.scss";

.bidDetailMainWrapper {
  .backArrowWrapper {
    display: flex;
    align-items: center;
  }
  .bid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    .bid-options-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 22px;
      .bid-status {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 17px;
        border-radius: 250px;
        font-family: "seventyTwoSemiBold";
        font-size: 12px;
        &.cancel {
          background: rgba(254, 37, 37, 0.1);
          color: rgba(254, 37, 37, 0.5);
        }
        &.complete {
          background: rgba(0, 164, 16, 0.1);
          color: rgba(0, 164, 16, 0.5);
        }
      }
    }
  }
  .tabsWrapper {
    display: flex;
    margin-top: 20px;

    .tabsItemWrapper {
      min-width: 226px;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 112, 242, 0.2);
      padding: 10px 10px 24px;
      cursor: pointer;

      @media (max-width: 600px) {
        min-width: 150px;
        min-height: 65px;

        .paragraph-parent p {
          font-size: 14px !important;
        }
      }
      @media (max-width: 400px) {
        min-width: 115px;
        min-height: 60px;

        .paragraph-parent p {
          font-size: 12px !important;
        }
      }
    }
    .tabsItemWrapper1 {
      border-radius: 20px 0px 0px 0px;
    }
    .tabsItemWrapper2 {
      border-radius: 0px 20px 0px 0px;
    }
    .activeTab {
      background-color: rgba(0, 112, 242, 1);

      .paragraph-parent p {
        color: #fff;
      }
    }
  }

  .ContentMainWrapper {
    background-color: #fff;
    border-radius: 20px;
    padding: 22px 20px;
    margin-top: -14px;
    z-index: 1;
    position: relative;
  }

  .sellerMainWrapper {
    margin-top: 20px;
  }

  .separator {
    background-color: rgba(231, 236, 241, 1);
    height: 1px;
    width: 100%;
    margin: 20px 0;
  }

  .downloadFileWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 112, 242, 0.1);
    padding: 16px 23px;
    border-radius: 12px;
    gap: 10px;

    .downloadFileWrap {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .documentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 980px) {
      display: block;
    }

    .documentItemWrapper2 {
      display: flex;
      align-items: normal;
      column-gap: 30px;

      @media (max-width: 980px) {
        display: block;
        margin-top: 10px;

        .button-parent {
          margin-top: 10px;
        }

        .paragraph-parent p {
          text-align: left !important;
        }
      }
    }
  }

  .planMainWrapper {
    .planDesc {
      @media (max-width: 980px) {
        margin-bottom: 20px !important;
      }
    }

    .planWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 980px) {
        display: block;
      }

      .planItemWrapper2 {
        max-width: 25%;

        @media (max-width: 980px) {
          max-width: 100%;
          margin-top: 10px;

          .paragraph-parent p {
            text-align: left !important;
          }
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    margin-top: 40px;

    @media (max-width: 767px) {
      display: block;
    }

    .buttons {
      min-width: 200px;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }

  .moreBidTableSection {
    margin-top: 20px;
  }

  .viewDetailButton {
    background-color: rgba(197, 222, 251, 1);
  }
}
.MoreBitContentWrapper {
  // .statusButton {
  //   padding: 3px 0;
  //   border-radius: 25px;
  //   text-align: center;
  //   width: 84px;

  //   background-color: master.$theme-light-color;
  //   p {
  //     font-family: "Lato";
  //     color: master.$text-greenish-color;
  //   }
  // }
  .statusButton {
    padding: 3px 0;
    border-radius: 25px;
    text-align: center;
    width: 84px;
    p {
      font-family: "Lato";
    }
  }

  .statusButton_open {
    background-color: master.$theme-light-color;
    p {
      color: master.$text-greenish-color;
    }
  }

  .statusButton_awarded {
    background-color: master.$theme-light-color;
    p {
      color: master.$theme-color;
    }
  }

  .statusButton_closed {
    background-color: #fce6e6;
    p {
      color: #e40404;
    }
  }
}
