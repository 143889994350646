@use "../../master.scss";

.private-content {
  margin-left: 240px;
  margin-top: 90px;
  padding: 30px 45px;
  background-color: #f6fafd;

  @include master.md {
    margin-left: 0;
    margin-top: 92px;
  }
  @include master.sm {
    padding: 30px 20px;
  }
}
