@use "../../../master.scss";

.main-header {
  width: calc(100% - 240px);
  padding: 20px 30px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  align-items: center;
  top: 0;
  z-index: 3;
  margin-left: 240px;

  @include master.md {
    width: 100%;
    margin: 0;
  }

  .sidebar-icon {
    position: absolute;
    left: 30px;
    cursor: pointer;
  }

  .notification-parent {
    position: relative;
    border: none;
    background: transparent;
    .notification-circle {
      position: absolute;
      border: 2px solid #fff;
      background-color: #ff6e91;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      right: 0;
    }
    .notification-dropdown {
      background: #fff;
      border-radius: 16px;
      height: 426px;
      width: 322px;
      position: absolute;
      bottom: -450px;
      left: -290px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 55px 99px 0px #a7b5be2b;
      transition: all 0.3s ease-in;
      opacity: 0;
      z-index: 999999;
      pointer-events: none;
      @media (max-width: 540px) {
        left: -121px;
      }
      @media (max-width: 450px) {
        left: -200px;
      }
      .notification-content {
        padding: 20px 10px 0;
        position: relative;
        .linear-blur {
          position: absolute;
          bottom: -134px;
          left: 134px;
          // height: 318px;
          width: 52px;
          transform: rotate(90deg);

          background: linear-gradient(
            92.3deg,
            #ffffff 2.46%,
            rgba(255, 255, 255, 0) 144.82%
          );
        }
        .notification-list {
          max-height: 378px;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .notification-item {
            width: 100%;
            padding: 12px 10px;
            cursor: pointer;
            border-bottom: 1px solid rgba(163, 174, 208, 0.3);
            &.unread {
              background: rgba(232, 234, 237, 0.5);
              border-radius: 8px;
              border: none;
            }

            .time {
              width: 80px;
              text-align: right;
            }
          }
        }
      }
    }
    &:focus-within {
      .notification-dropdown {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
